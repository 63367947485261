import * as Sentry from "@sentry/nextjs";
import { SessionProvider, signIn, useSession } from "next-auth/react";
import type { AppProps } from "next/app";
import * as React from "react";
import {
  RelayEnvironmentProvider,
  graphql,
  useLazyLoadQuery,
} from "react-relay/hooks";
import UseAnimations from "react-useanimations";
import loading2 from "react-useanimations/lib/loading2";

import { Toaster, TooltipProvider } from "@dewrangle/ui";

import { useClientEnvironment } from "@/lib/relay/clientEnvironment";
import { AppIsViewerQuery } from "@/queries/__generated__/AppIsViewerQuery.graphql";

import "../styles/globals.css";

const AuthenticatedRoute = ({
  unauthenticatedRoute,
  children,
}: {
  unauthenticatedRoute: boolean;
  children: React.ReactNode;
}) => {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const isUser = !!session?.user;

  const { viewer } = useLazyLoadQuery<AppIsViewerQuery>(
    graphql`
      query AppIsViewerQuery {
        viewer {
          id
        }
      }
    `,
    {}
  );
  const isViewer = !!viewer?.id;

  if (session?.user?.email) {
    Sentry.getCurrentScope().setUser({
      email: session.user.email,
    });
  }

  // If not authenticated, force log in
  React.useEffect(() => {
    if (!unauthenticatedRoute && !loading && (!isUser || !isViewer)) {
      void signIn("auth0", undefined, { prompt: "login" });
    }
  }, [unauthenticatedRoute, isUser, isViewer, loading]);

  return unauthenticatedRoute || isUser ? (
    <>{children}</>
  ) : (
    <div className="relative min-h-screen flex place-content-center place-items-center">
      <UseAnimations animation={loading2} size={64} />
    </div>
  );
};

const App = ({ Component, pageProps }: AppProps) => {
  const { clientEnvironment } = useClientEnvironment();
  return (
    <SessionProvider session={pageProps.session}>
      <TooltipProvider delayDuration={0}>
        {clientEnvironment && (
          <RelayEnvironmentProvider environment={clientEnvironment}>
            <React.Suspense
              fallback={
                <div className="relative min-h-screen flex place-content-center place-items-center">
                  <UseAnimations animation={loading2} size={64} />
                </div>
              }
            >
              <AuthenticatedRoute
                unauthenticatedRoute={!!Component.unauthenticatedRoute}
              >
                <Component {...pageProps} />
              </AuthenticatedRoute>
            </React.Suspense>
          </RelayEnvironmentProvider>
        )}
        <Toaster />
      </TooltipProvider>
    </SessionProvider>
  );
};

export default App;
