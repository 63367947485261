import * as Sentry from "@sentry/nextjs";
import type { NextPageContext } from "next";
import { Network } from "relay-runtime";

import { absoluteUrl } from "@/lib/next";

import { shouldInitializeSentry } from "../env";
import { fetchWithRetry } from "./fetchWithRetry";

// Define function withHydrateDatetime locally, to avoid importing from relay-nextjs library
// Ref: https://github.com/RevereCRE/relay-nextjs/blob/main/relay-nextjs/src/wired/date.ts
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/;
export function withHydrateDatetime(
  _key: string,
  value: unknown
): Date | unknown {
  // `new Date` accepts things that aren't necessarily valid JSON timestamps,
  // for example `new Date('80')` works. Validating that we're actually
  // converting a JSON timestamp avoids unintentional coercion.
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }
  return value;
}

export const createNetwork = (ctx?: NextPageContext) =>
  Network.create(async (params, variables) => {
    const relayRequest = async () => {
      const { origin } = absoluteUrl(ctx?.req);
      const response = await fetchWithRetry(`${origin}/api/graphql`, {
        method: "POST",
        headers: new Headers({
          "content-type": "application/json",
          ...(ctx?.req && { cookie: ctx.req.headers.cookie }),
        }),
        body: JSON.stringify({
          query: params.text,
          variables,
        }),
        credentials: "same-origin",
      });

      const json = await response.text();
      return JSON.parse(json, withHydrateDatetime);
    };

    if (shouldInitializeSentry()) {
      return await Sentry.startSpan(
        { name: params.name, op: `http.graphql.${params.operationKind}` },
        async () => {
          return await relayRequest();
        }
      );
    } else {
      return await relayRequest();
    }
  });
