/**
 * @generated SignedSource<<787f2bcb83408525118024265fd545a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppIsViewerQuery$variables = Record<PropertyKey, never>;
export type AppIsViewerQuery$data = {
  readonly viewer: {
    readonly id: string;
  } | null | undefined;
};
export type AppIsViewerQuery = {
  response: AppIsViewerQuery$data;
  variables: AppIsViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppIsViewerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppIsViewerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "11ca3c0d5af215a2de54bf8acd4c40ac",
    "id": null,
    "metadata": {},
    "name": "AppIsViewerQuery",
    "operationKind": "query",
    "text": "query AppIsViewerQuery {\n  viewer {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f86c7de4cc569be4e28d36822501c54";

export default node;
