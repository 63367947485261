import type { IncomingMessage } from "http";

export const getHostFromRequest = (
  req?: IncomingMessage
): string | undefined => {
  if (typeof req?.headers["x-forwarded-host"] === "string") {
    return req.headers["x-forwarded-host"];
  }

  if (typeof req?.headers.host === "string") {
    return req.headers.host;
  }

  return undefined;
};

export const getProtocolFromRequest = (
  req?: IncomingMessage
): string | undefined => {
  if (typeof req?.headers["x-forwarded-proto"] === "string") {
    return `${req.headers["x-forwarded-proto"]}:`;
  }

  return undefined;
};

export const isLocalhost = (host: string): boolean => {
  return /^localhost(:\d+)?$/.test(host);
};

export const getProtocolFromHost = (host: string): string => {
  return isLocalhost(host) ? "http:" : "https:";
};

export const absoluteUrl = (
  req?: IncomingMessage,
  localhostAddress = "localhost:3000"
) => {
  const host =
    getHostFromRequest(req) ?? window.location.host ?? localhostAddress;
  const protocol = getProtocolFromRequest(req) ?? getProtocolFromHost(host);

  return {
    protocol,
    host,
    origin: protocol + "//" + host,
  };
};
