import { useEffect, useState } from "react";
import { Environment, RecordSource, Store } from "relay-runtime";

import { createNetwork } from "./config";

let clientEnv: Environment | undefined;
const getClientEnvironment = () => {
  if (!clientEnv) {
    clientEnv = new Environment({
      network: createNetwork(),
      store: new Store(new RecordSource()),
      isServer: false,
    });
  }
  return clientEnv;
};

export function useClientEnvironment() {
  const [clientEnvironment, setClientEnvironment] =
    useState<Environment | null>(null);

  useEffect(() => {
    setClientEnvironment(getClientEnvironment());
  }, []);

  return { clientEnvironment };
}
