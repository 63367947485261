import { invariant } from "../types";

// This cannot be destructured from process.env because if we would like
// NEXTJS to inline this for use on the client.
// https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
export const NEXT_PUBLIC_APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

export function isPreview() {
  return NEXT_PUBLIC_APP_ENV === "preview";
}

export function isDevelopment() {
  return NEXT_PUBLIC_APP_ENV === "development";
}

export function isTest() {
  return NEXT_PUBLIC_APP_ENV === "test";
}

export function isProduction() {
  return NEXT_PUBLIC_APP_ENV === "production";
}

export function devOnlyServerSideProps() {
  if (isPreview() || isDevelopment()) {
    return { props: {} };
  }
  return { notFound: true };
}

export function shouldInitializeSentry() {
  return (
    (!isDevelopment() && !isTest()) ||
    process.env.NEXT_PUBLIC_ENABLE_SENTRY_DEV === "1"
  );
}

export function invariantEnv<T>(condition: T, env: string): asserts condition {
  return invariant(
    condition,
    `Environment variable "${env}" is missing or empty.`
  );
}

export const getFhirEnv = () => {
  invariantEnv(process.env.FHIR_URL, "FHIR_URL");
  return {
    FHIR_URL: process.env.FHIR_URL,
  };
};
