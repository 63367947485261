import * as Sentry from "@sentry/nextjs";

export async function fetchWithRetry(
  url: string,
  options: RequestInit,
  retries = 3,
  backoff = 300
): Promise<Response> {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! url: ${url} status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      // We aren't yet sure _why_ fetches sometimes fail on pages like the volume page
      // on production, so we want to see if this continues to occur and if retries help.
      Sentry.captureMessage(
        `Fetch failed at ${url} at ${retries} retries, retrying...`
      );
      await new Promise((resolve) => setTimeout(resolve, backoff)); // Wait for backoff milliseconds
      return fetchWithRetry(url, options, retries - 1, backoff * 2); // Exponential backoff
    } else {
      throw error; // Propagate the error if no retries are left
    }
  }
}
